import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InformationGenaraleService {

  constructor() { }

  baseURL = "https://www.aaconcept-renov.com/assets/backend/public/"
  //baseURL = "http://127.0.0.1:8000/"
  
}
