import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renovationfacade',
  templateUrl: './renovationfacade.component.html',
  styleUrls: ['./renovationfacade.component.css']
})
export class RenovationfacadeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
