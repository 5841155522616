<app-slider></app-slider>

<app-aboute-home></app-aboute-home>

<app-blog2-home></app-blog2-home>

<app-blog3-home></app-blog3-home>

<app-blog4-home></app-blog4-home>

<app-partenaire></app-partenaire>

