import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enduitdecoratif',
  templateUrl: './enduitdecoratif.component.html',
  styleUrls: ['./enduitdecoratif.component.css']
})
export class EnduitdecoratifComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
