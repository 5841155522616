<div class="item-slider slider1">
    <div class="container">
      <div class="banner-info-bg">
        <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            quisquam, doloremque placeat aut numquam ipsam. </p>
          <br>
          <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            quisquam, doloremque placeat aut numquam ipsam. </p>
          <br>
          <h5>{{name}}</h5>
          <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            quisquam, doloremque placeat aut numquam ipsam. </p>
          <br>
       </div>
    </div>
  </div>
