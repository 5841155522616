<app-sliderpage name="Services"></app-sliderpage>




<div class="content">
    <div class="row" style="margin:0px; "> 
    
           <div class="col-sm-3" style="margin-top:90px;">
               <ul>
                   <li><a [routerLink]="['/Enduit-decoratif']">Enduit Decoratif</a></li>
                   <li><a [routerLink]="['/Nettoyage-toiture']">Nettoyage Toiture</a></li>
                   <li><a [routerLink]="['/Renovation-facade']">Renovation Facade</a></li>
                   <li><a [routerLink]="['/Renovation-interieur']">Renovation Interieur</a></li>
               </ul> 
            </div>
           
            <div class="col-sm-9">
                <br>
                <h2 style="text-align: center;">Enduit Decoratif</h2>
             
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                      <img
                      src="assets/images/enduit decoratif/242963312_828143791200304_4137933202361114300_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                  
                      <img
                      src="assets/images/enduit decoratif/245045517_557405018923006_8645785800294514939_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Wintry Mountain Landscape"
                      />
                    </div>
                  
                    <div class="col-lg-4 mb-4 mb-lg-0">
                      <img
                      src="assets/images/enduit decoratif/245052998_172726015043162_5163240498457809739_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Mountains in the Clouds"
                      />
                  
                      <img
                      src="assets/images/enduit decoratif/245105391_273333218006523_6755481009458264955_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                    </div>
                  
                    <div class="col-lg-4 mb-4 mb-lg-0">
                      <img
                      src="assets/images/enduit decoratif/245216799_2039346669555042_1272558352253162249_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />
                  
                    </div>
                  </div>
            </div>
        
    </div>
</div>