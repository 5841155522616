
<div style="position:relative; width: 100%;">
    
    <div style="position: absolute; height: 100%; width: 100%; top:0px; left:0px; background-color: rgba(0, 0, 0, 0.507);"></div>

    <div class="conteneur-slider">
      
       <div class="item-slider slider1">
            <div class="container">
              <div class="banner-info-bg" >
                <h5>Votre Satisfaction est notre priorité</h5>
                <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                  quisquam, doloremque placeat aut numquam ipsam. </p>
                  
                  <a class="btn btn-style btn-white mt-sm-5 mt-4" [routerLink]="['/Demandedevis']"> Demande Devis</a>
                  <a class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" [routerLink]="['/Contact']"> Contact</a>
            
              </div>
            </div>
        </div>
        
        <div class="item-slider slider2">
          <div class="container">
            <div class="banner-info-bg" >
                <h5>Les particuliers et les professionneles parlent de nous</h5>
                <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                  quisquam, doloremque placeat aut numquam ipsam. </p>
  
                <a class="btn btn-style btn-white mt-sm-5 mt-4" [routerLink]="['/Demandedevis']"> Demande Devis</a>
                <a class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" [routerLink]="['/Contact']"> Contact</a>
                   
              </div>
            </div>
        </div>
      
        <div class="item-slider slider3">
          <div class="container">
            <div class="banner-info-bg">
                <h5>Votre maison de demain passe entre nos belles mains</h5>
                <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                  quisquam, doloremque placeat aut numquam ipsam. </p>
                
                <a class="btn btn-style btn-white mt-sm-5 mt-4" [routerLink]="['/Demandedevis']"> Demande Devis</a>
                <a class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" [routerLink]="['/Contact']"> Contact</a>
                   
             </div>
          </div>
        </div>

        <div class="item-slider slider4">
          <div class="container">
            <div class="banner-info-bg">
                <h5>Votre maison de demain passe entre nos belles mains</h5>
                <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                  quisquam, doloremque placeat aut numquam ipsam. </p>
                
                <a class="btn btn-style btn-white mt-sm-5 mt-4" [routerLink]="['/Demandedevis']"> Demande Devis</a>
                <a class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" [routerLink]="['/Contact']"> Contact</a>
                   
             </div>
          </div>
        </div>
        
      
        <div style="position:absolute; width:100%; height: 10%; margin:auto; top:0px; bottom:0px; padding: 10px;">
            <button class="w3-button w3-display-left" (click)="plusDivs(-1)">&#10094; </button>
            <button class="w3-button w3-display-right" (click)="plusDivs(+1)" style="float:right;">&#10095;</button>
        </div>
        
    </div>
    
</div>
