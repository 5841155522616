<section class="w3l-portfolio-8 py-5">
    <div class="portfolio-main py-md-5 py-3">
      <div class="container">
        <div class="header-section text-center mx-auto">
          <h3 class="hny-title">DEVIS TRAVAUX LES PLUS DEMANDÉS</h3>
          <p class="my-3"> Choisissez les travaux que vous souhaitez réaliser : nous vous accompagnons dans le choix du prestataire et nous vous donnons de nombreux conseils pour les réussir.</p>
        </div>
        <div class="row galler-top mt-lg-5 mt-4">
          <div class="col-md-4 protfolio-item hover14">
            <a  data-lightbox="example-set" class="mb-4" data-title="lorem ipsum dolor sit amet">
              <figure style="position: relative;">
                <img src="assets/images/enduit decoratif/service1.jpg"  alt="product" class="img-fluid">
                <div class="style-animation-image">
                  <h2 class="style-animation-text">Enduit Decoratif</h2>
                </div>
         
              </figure>
            
            </a>
            <a  data-lightbox="example-set" class="mb-md-0 mb-4" data-title="lorem ipsum dolor sit amet">
              <figure>
                <img src="assets/images/enduit decoratif/service2.jpg" alt="product" class="img-fluid">
                <div class="style-animation-image">
                  <h2 class="style-animation-text">Enduit Decoratif</h2>
                </div>
         
              </figure>
            </a>
          </div>
          <div class="col-md-4 protfolio-item hover14">
            <a  data-lightbox="example-set" class="mb-4" data-title="lorem ipsum dolor sit amet">
              <figure>
                <img src="assets/images/nettoyage de toiture/243209429_673265273656598_3735081411463269413_n.jpg" alt="product" class="img-fluid">
                <div class="style-animation-image">
                  <h2 class="style-animation-text">Nettoyage Toiture</h2>
                </div>
         
              </figure>
            </a>
            <a  data-lightbox="example-set" class="mb-md-0 mb-4" data-title="lorem ipsum dolor sit amet">
              <figure>
                <img src="assets/images/renovation_de_facade/service4.jpg" alt="product" class="img-fluid">
                <div class="style-animation-image">
                  <h2 class="style-animation-text">Renovation Facade</h2>
               
                </div>
         
              </figure>
            </a>
          </div>
          <div class="col-md-4 protfolio-item hover14">
            <a  data-lightbox="example-set" class="mb-4" data-title="lorem ipsum dolor sit amet">
              <figure>
                <img src="assets/images/nettoyage de toiture/service3.jpg" alt="product" class="img-fluid">
                <div class="style-animation-image">
                  <h2 class="style-animation-text">Nettoyage Toiture</h2>
               
                </div>
         
              </figure>
            </a>
            <a  data-lightbox="example-set" data-title="lorem ipsum dolor sit amet">
              <figure>
                <img src="assets/images/renovation interieur/service5.jpg" alt="product" class="img-fluid">
                <div class="style-animation-image">
                  <h2 class="style-animation-text">Renovation Interieur</h2>
               
                </div>
         
              </figure>
            </a>
          </div>

        </div>
      </div>
    </div>
  </section>