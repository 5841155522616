import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboute-home',
  templateUrl: './aboute-home.component.html',
  styleUrls: ['./aboute-home.component.css']
})
export class AbouteHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
