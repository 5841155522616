<app-sliderpage name="Services"></app-sliderpage>




<div class="content">
    <div class="row" style="margin:0px;">
  
    
      <div class="col-sm-3" style="margin-top:90px;">
        <ul>
            <li><a [routerLink]="['/Enduit-decoratif']">Enduit Decoratif</a></li>
            <li><a [routerLink]="['/Nettoyage-toiture']">Nettoyage Toiture</a></li>
            <li><a [routerLink]="['/Renovation-facade']">Renovation Facade</a></li>
            <li><a [routerLink]="['/Renovation-interieur']">Renovation Interieur</a></li>
        </ul> 
     </div>
           
            <div class="col-sm-9">
                <br>
                <h2 style="text-align: center;">Renovation Interieur</h2>
             
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                      <img
                      src="assets/images/renovation interieur/245032708_1196679287497810_1289846761921114913_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                  
                      <img
                      src="assets/images/renovation interieur/245036773_252012653551589_7981291383659713207_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Wintry Mountain Landscape"
                      />
                    </div>
                  
                    <div class="col-lg-4 mb-4 mb-lg-0">
                      <img
                      src="assets/images/renovation interieur/245061118_251887790204884_3987748038000271492_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Mountains in the Clouds"
                      />
                  
                      <img
                      src="assets/images/renovation interieur/245111445_561406048301043_7208869307182391713_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                    </div>
                  
                    <div class="col-lg-4 mb-4 mb-lg-0">
                      <img
                      src="assets/images/renovation interieur/245244474_169965721974848_6225583639261440387_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />

                      <img
                      src="assets/images/renovation interieur/245277748_626447242071413_7792198939603740170_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />

                      <img
                      src="assets/images/renovation interieur/jbnjknjkn.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />
                  
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img
                        src="assets/images/renovation interieur/245496903_638787930619688_1940978214046299345_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
  
                      
                    
                      </div>
                  </div>
            </div>
        
    </div>
</div>