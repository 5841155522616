
 <div class="item-slider slider1">
  <div class="container">
    <div class="banner-info-bg">
      <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
          quisquam, doloremque placeat aut numquam ipsam. </p>
        <br>
        <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
          quisquam, doloremque placeat aut numquam ipsam. </p>
        <br>
        <h5>Avis de clients</h5>
        <p class="mt-4 pr-lg-4" style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
          quisquam, doloremque placeat aut numquam ipsam. </p>
        <br>
        
           
     </div>
  </div>
</div>


<section class="w3l-grids-3 py-5" id="about">
  <div>
    <h2 style="color: white; text-align: center;background: linear-gradient(to right, #8bdf06, rgb(94, 185, 228));"> Avis de clients </h2>
  </div>
  <br>
  <br>
  <br>      

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-6 bottom-ab-left w3l-cwp4-sec py-5">
      <span style="float: left;padding: 15px; margin:auto"> <img style="width: 180px;height: 160px;;" src="./assets/images/client1.jpg" class="img-fluid" alt=""> </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;">Swann Haddou &#9997; recommande Aaconceptrenovo..  </p>
      <br>
      <br>
      <br>      
      <br>
      <p class="my-3"> Ma rénovation est terminée et je dois dire que je suis très satisfaite du résultat !
        Une entreprise sérieuse et soucieus de bien faire, à l'écoute et serviable pour tous les petits besoins et divers brocolages liès à l'embellisement de l'appartement !
        Un grand merci. &#128150; 
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
      <img src="./assets/images/avis1.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12 ">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> Francesca Tinnirinoĺ &#9997; recommande Aaconceptrenovo..  </p>
     <p class="my-3"> 
        Cahier de charge remplie: démolition de paroie, revêtement de sol, peinture murs, portes,
plafonds respecté dans les délais demandé. Flexible, perfectionnisme aux details et prise d
initiative très appréciée lors d imprévu. Je recommande vivement cette entreprise en tout
confiance. Une collaboration parfaite, fluide et naturelle. Je reviendrai vers elle pour un futur
projet sans hésiter. Merci à toute l équipe 
      </p>
    </div>
    
  </div>
</div>


<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12 ">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> vincent duong &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
  Entreprise réactive et qui s&#39;implique pour satisfaire au mieux son client. Même sur les chantiers
les plus difficiles, AA Concept sait s&#39;adapter et un à prix bien placé. Pour ma part, travaux
exécutés comme prévus ! 
      </p>
    </div>
    
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> a s &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        Très bonnes expériences !! Entièrement satisfait et prestations de qualité pour un prix très
raisonnable. Les artisans sont très compétents dans leur domaine: ils ont refait à neuf et mis aux
normes l&#39;électricité, le parquet, la cuisine, la douche à l&#39;italiennet, la peinture, reconstruction de
cloison de la chambre et du salon et ventilation. ils sont très arrangeants, ponctuels et ils font
visiter régulièrement l&#39;avancement des travaux 1 fois par semaine. Les délais sont largement
respectés et les livraisons de qualité. Si j&#39;ai d&#39;autres gros travaux de rénovation, je n&#39;hésiterai pas
à refaire appel à eux, c&#39;est sûr. Quelques photos après/avant.
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12 ">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> Nolwenn Cadic &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        Je recommande vivement A.A Concept Renov. J&#39;ai déjà confié 3 chantiers à Kaies (rénovation
complète de T5), et j&#39;ai été très satisfaite des résultats à chaque fois. Kaies est force de
proposition, il a de très bonnes idées. Il sait gérer les mauvaises surprises (et soyons honnête il y
en a toujours pendant des gros travaux de rénovation). Il est très réactif lorsque des modifications
sont demandées, et assure le service après vente. Plus d&#39;un an après la fin des travaux, il
continue d&#39;intervenir en cas de problème.
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> tournadre laurent &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        Première expérience avec un artisan pour des gros travaux de rénovation. Et une très bonne
surprise ! : Kaies et son équipe se montre à l&#39;écoute de nos souhaits, sait proposer des idées
intéressantes et est toujours disponible. Il assure en plus le service après vente, et avec le
sourire �� Nous ne manquerons pas de refaire appel à lui pour les prochains travaux de
rénovation !
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> Nisrine Bghiel &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        Je travaille depuis quelques mois en étroite collaboration avec Mr Gharsallah sur plusieurs
projets; je peux dire qu&#39; il est professionnel, arrangeant, soucieux du détail, à l&#39;écoute et propose
des services avec un très bon rapport qualité prix. Je recommande vivement!
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> Sparx &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        Très bonne expérience avec cette société, très à l&#39;écoute de mes exigences, tous les travaux ont
        été réalisés proprement, dans des délais et des tarifs corrects.Je recommande
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> New Life &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        En plus de son incroyable rapport qualité/prix, cet artisan est très professionnel. Il a des équipes
sérieuses et qui respectent les délais. Je recommande les yeux fermés !!!
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>

<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-12">
      <span style="float: left;padding: 15px; margin:auto">  </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;"> andre.illico travaux &#9997; recommande Aaconceptrenovo..  </p>
      <p class="my-3"> 
        M. GHARSALLAH est très arrangeant et réactif. Je recommande.
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
     
    </div>
  </div>
</div>
</section>      




