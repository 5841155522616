

<section class="footer-22">
  <div class="gallery-inner container py-md-5 py-3">
      <div class="row stats-con">
          <div class="col-lg-3 stats_info counter_grid class1-footer" style="text-align: center;">
            
            <h2>Contactez-nous</h2>
            <p>Un projet&nbsp;? Une question&nbsp;?</p>
            <a [routerLink]="['/Contact']" class="footer-button">Contact</a><br><br>
  
          </div>
          <div class="col-lg-3 stats_info counter_grid class2-footer">
            
            <h3>Société <br> Aa.concept-Renov</h3>
            <p>Ambilly 74 100 </p>
            <a href="tel:+33 06 73 51 60 30" class="tel">06 73 51 60 30</a><br><br>
            <a href="mailto:contact@archenergie.fr" class="mail">info@aaconcept-renov.com</a>
            <br><br>

          </div>
          
          <div class="col-lg-3 stats_info counter_grid mt-lg-0 mt-5 class3-footer">
            <h2>Suivez-nous</h2>
            <a href="https://www.facebook.com/Aaconceptrenov-112363667771289/" target="_blank" class="social" title="Suivre notre actualité sur Facebook">
              <img src="./assets/images/fb.png">
            </a>
            <a href="#" target="_blank" class="social" title="Visionner nos vidéos">
                  <img src="./assets/images/ink.png">
              </a>
              <a href="#" target="_blank" title="Suivre notre actualité sur Pinterest" id="yt" class="social">
              <img src="./assets/images/inst.png"> 
            </a>
          </div>

          <div class="col-lg-3 stats_info counter_grid mt-lg-0 mt-5 class4-footer">
            <div class="column1 align-self">
              <h6 class="footer-title-29 mb-1">Subscribe to our Newsletter </h6>
              <p>Enter your email and receive the latest news from us.</p>
            </div>
            <div class="column1">
              <div action="#" class="subscribe" method="post">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Entrer votre email" aria-label="Recipient's username" aria-describedby="button-addon2" [(ngModel)]="request.email">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="controleInput()" style="color: white; border: white 2px solid;"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
          </div>

      </div>
  </div>
</section>

<section class="w3l-footer-29-main" style="background-color: #1b1a1a;">
   <div class="footer-29 py-5">
     <div class="container py-lg-4">
       <div class="row footer-top-29">
         <div class="footer-list-29 col-lg-4">
           <h6 class="footer-title-29">Présentation</h6>
           <p>La société A.a.concept renov est fondée en 2015 ,nous mettons à votre disposition une équipe fiable et dévouée. Notre entreprise
              de rénovation vous propose un service hautement professionnel pour la rénovation intérieure et extérieure... 
            
              <a [routerLink]="['/Presentation']">Lire suite</a> </p>
           
         </div>

         <div class="col-lg-2 col-md-6 col-sm-4 footer-list-29 footer-2 mt-lg-0 mt-5">

           <ul>
             <h6 class="footer-title-29">Site-Maps</h6>
             <li><a [routerLink]="['/accueil']">Accueil</a></li>
             <li><a [routerLink]="['/Presentation']">Présentation</a> </li>
             <li><a [routerLink]="['/Realisations']">Réalisations</a> </li>
             <li><a [routerLink]="['/Services']">Services</a> </li>
             <li><a [routerLink]="['/Avantapres']">Avant/Après</a> </li>
             <li><a [routerLink]="['/Images']">Avis clients</a> </li>
             <li><a [routerLink]="['/Contact']">Contact</a> </li>
             <li><a [routerLink]="['/Demandedevis']">Devis</a> </li>
   
           </ul>
         </div>
         <div class="col-lg-3 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5">

           <ul>
             <h6 class="footer-title-29">Services</h6>
             <li><a [routerLink]="['/Services']">Enduit Decoratif</a></li>
             <li><a [routerLink]="['/Services']">Nettoyage Toiture</a></li>
             <li><a [routerLink]="['/Services']">Renovation Facade</a></li>
             <li><a [routerLink]="['/Services']">Renovation Interieur</a></li>
           </ul>

         </div>
         <div class="col-lg-3 col-md-6 col-sm-8 footer-list-29 footer-1 mt-lg-0 mt-5">
           <h6 class="footer-title-29">Contact Us</h6>
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11046.97163744175!2d6.215172189417566!3d46.195674465860726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c6fd432ec967f%3A0x408ab2ae4baa630!2sAmbilly%2C%20France!5e0!3m2!1sfr!2stn!4v1638309006273!5m2!1sfr!2stn" width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
         </div>
       
       </div>
     </div>
   </div>
 </section>