<app-sliderpage name="Avant-Après"></app-sliderpage>


<div class="container" style="padding-top: 50px; padding-bottom: 50px;">
    <div class="col-12">
      <div class="row">
  
  
        <div class="col-sm-6 item-realisation">
          <div>
            <img src="assets/images/banner1.jpg" alt="product" class="img-fluid">
            <div class="style-animation-image">
              <h2 class="style-animation-text">Projet-1 (Avant)</h2>
            </div>
          </div>
        </div>
  
        <div class="col-sm-6 item-realisation">
          <div>
            <img src="assets/images/banner2.jpg" alt="product" class="img-fluid">
            <div class="style-animation-image">
              <h2 class="style-animation-text">Projet-1 (Après)</h2>
            </div>
          </div>
        </div>
  
        <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/nettoyage de toiture/apresavant1.jpg" alt="product" class="img-fluid">
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-2 (Avant)</h2>
              </div>
            </div>
          </div>
    
          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/nettoyage de toiture/service3.jpg" alt="product" class="img-fluid">
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-2 (Après)</h2>
              </div>
            </div>
          </div>

          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/renovation interieur/apresavant2.jpg" alt="product" class="img-fluid">
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-3 (Avant)</h2>
              </div>
            </div>
          </div>
    
          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/renovation interieur/apresavant3.jpg" alt="product" class="img-fluid">
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-3 (Après)</h2>
              </div>
            </div>
          </div>

          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/AV  APRES.jpg" alt="product" class="img-fluid">
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-4 (Avant/Après)</h2>
              </div>
            </div>
          </div>
    
          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/AV APRESS.jpg" alt="product" class="img-fluid"> 
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-5 (Avant/Après)</h2>
              </div>
            </div>
          </div>

          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/AVV APPR.jpg" alt="product" class="img-fluid">
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-6 (Avant/Après)</h2>
              </div>
            </div>
          </div>
    
          <div class="col-sm-6 item-realisation">
            <div>
              <img src="assets/images/AVV APRES.jpg" alt="product" class="img-fluid"> 
              <div class="style-animation-image">
                <h2 class="style-animation-text">Projet-7 (Avant/Après)</h2>
              </div>
            </div>
          </div>
    
  
  
      </div>
    </div>
  </div>