<app-sliderpage name="Réalisation"></app-sliderpage>

<div class="container" style="padding-top: 50px; padding-bottom: 50px;">
  <div class="col-12">
    <div class="row">


      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/enduit decoratif/service1.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Enduit Decoratif</h2>
          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/enduit decoratif/decor1.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Enduit Decoratif</h2>
          </div>
        </div>
      </div>
      
      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/enduit decoratif/deco6.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Enduit Decoratif</h2>
          </div>
        </div>
      </div>
      
      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/enduit decoratif/deco7.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Enduit Decoratif</h2>
          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/enduit decoratif/deco8.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Enduit Decoratif</h2>
          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/enduit decoratif/service2.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Enduit Decoratif</h2>
          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/nettoyage de toiture/243209429_673265273656598_3735081411463269413_n.jpg"
            alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Nettoyage Toiture</h2>
          </div>

        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation_de_facade/service4.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Facade</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation_de_facade/deco11.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Facade</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation_de_facade/deco12.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Facade</h2>

          </div>
        </div>
      </div> 

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/nettoyage de toiture/service3.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Nettoyage Toiture</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/service5.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/decor2.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/decor3.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/deco4.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/deco5.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/deco9.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/deco10.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>

      <div class="col-sm-6 item-realisation">
        <div>
          <img src="assets/images/renovation interieur/deco13.jpg" alt="product" class="img-fluid">
          <div class="style-animation-image">
            <h2 class="style-animation-text">Renovation Interieur</h2>

          </div>
        </div>
      </div>



    </div>
  </div>
</div>