<section class="w3l-grids-3 py-5" id="about">
   <div>
     <h2 style="color: #293f05; text-align: center;"> Qui somme nous ? </h2>
   </div>
    <div class="container py-md-5 py-3" style="padding-top: 0px !important;">
      <div class="row bottom-ab-grids align-items-center">

        <div class="col-lg-6 bottom-ab-left">
          <h6 class="sub-titlehny"> <img src="./assets/images/Untitled-2.png" class="img-fluid" alt=""> </h6>
          <h3 class="hny-title">La qualité de notre travail est le produit de notre professionnalisme  </h3>
          <p class="my-3"> La société A.a.concept renov est fondée en 2015 ,nous mettons à votre disposition une équipe fiable et dévouée.
            Notre entreprise de rénovation vous propose un service hautement professionnel pour 
            la rénovation intérieure et extérieure qui réalise tous travaux  dans les domaines suivants : 
             </p>
        </div>
        <div class="col-lg-6 bottom-ab-right mt-lg-0 mt-3 pl-lg-4">
          <p> Nettoyage & Hydrofuge de toiture et façade, Sanitaire ,  placo-platre, isolation, Rénovation totale, cloisons sèches, revêtements de sol, carrelage, parquet, peinture, décoration, électricité, ,Crepi 
          </p>
          <p class="mt-3">Confier vos travaux de rénovation à A.a.concept renov c'est vous assurer un résultat de qualité à des prix attractifs et des délais respectés.</p>
          <p><a [routerLink]="['/Presentation']">Lire la suite ...</a></p>
        </div>

      </div>
      <div class="row bottom_grids pt-md-3 text-left">
        <div class="col-lg-4 col-md-6 mt-5">
          <div class="grid-block">
            <a href="#" class="d-block p-lg-4 p-3">
              <span class="fa fa-cubes" aria-hidden="true"></span>
              <h4 class="my-3">Une clientèle diversifiée</h4>
              <p class="">Nous travaillons aussi bien pour les particuliers que pour les professionnels.</p>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-5">
          <div class="grid-block">
            <a href="#" class="d-block p-lg-4 p-3">
              <span class="fa fa-building" aria-hidden="true"></span>
              <h4 class="my-3">La qualité de services</h4>
              <p class="">Nous garantissons un résultat de qualité pour votre satisfaction.</p>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-5">
          <div class="grid-block">
            <a href="#" class="d-block p-lg-4 p-3">
              <span class="fa fa-podcast" aria-hidden="true"></span>
              <h4 class="my-3">Notre polyvalence</h4>
              <p class="">Nous réalisons tout type de projet : construction et rénovation.</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
