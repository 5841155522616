<app-sliderpage name="Services"></app-sliderpage>




<div class="content">
    <div class="row" style="margin:0px;">
  
    
      <div class="col-sm-3" style="margin-top:90px;">
        <ul>
            <li><a [routerLink]="['/Enduit-decoratif']">Enduit Decoratif</a></li>
            <li><a [routerLink]="['/Nettoyage-toiture']">Nettoyage Toiture</a></li>
            <li><a [routerLink]="['/Renovation-facade']">Renovation Facade</a></li>
            <li><a [routerLink]="['/Renovation-interieur']">Renovation Interieur</a></li>
        </ul> 
     </div> 
           
            <div class="col-sm-9">
                <br>
                <h2 style="text-align: center;">Renovation Facade</h2>
             
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                        <img
                        src="assets/images/renovation_de_facade/243140707_400781178296405_5815398119265706037_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Boat on Calm Water"
                        />
                    
                        
                        <img
                        src="assets/images/renovation_de_facade/245039451_561469238271921_2742018048788782285_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Wintry Mountain Landscape"
                        />
                        <img
                        src="assets/images/renovation_de_facade/244991470_2886372838293298_2509868276961887530_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Mountains in the Clouds"
                        />
                    
                        <img
                        src="assets/images/renovation_de_facade/245053511_1958642014290771_5304853573811692574_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Boat on Calm Water"
                        />
  
                        <img
                        src="assets/images/renovation_de_facade/245075909_946834919203377_587260765702211649_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                        <img
                        src="assets/images/renovation_de_facade/245092992_304055711123061_7657800286937282275_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                    
                      </div>
                      <div class="col-lg-4 mb-4 mb-lg-0">
                          <img
                          src="assets/images/renovation_de_facade/245105389_994905357744183_5901856726215735777_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                          <img
                          src="assets/images/renovation_de_facade/245138666_262940392424454_8053330746784004184_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                    
                          <img
                          src="assets/images/renovation_de_facade/245209008_2969508559977847_8533214740092095960_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                          <img
                          src="assets/images/renovation_de_facade/245242470_1402839066804720_672242354373824123_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                    
                          <img
                          src="assets/images/renovation_de_facade/245301239_159679363036521_7087410465663812608_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                         
                    
                        </div>
  
                    
                        <div class="col-lg-4 mb-4 mb-lg-0">
                          <img
                          src="assets/images/renovation_de_facade/245353796_3038887689691474_8994383024846244517_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                          <img
                          src="assets/images/renovation_de_facade/245422645_367898588351248_5875173887744013258_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                    
                          <img
                          src="assets/images/renovation_de_facade/245491496_400749785008296_6937018892890031754_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                          <img
                          src="assets/images/renovation_de_facade/IMG-20211010-WA0004.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />

                          <img
                          src="assets/images/renovation_de_facade/245312369_427081118837360_628618179676255964_n.jpg"
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Waves at Sea"
                          />
                    
                        </div>
  
                  </div>
            </div>
        
    </div>
</div>