<div class="col-12">
    <h4> 3- Décrivez-nous votre projet</h4>

    <div class="form-group" style="width: 100%;">
      <textarea class="form-control" id="projet" rows="3"  [(ngModel)]="projet" ></textarea>
      <p *ngIf="erreurProjet != ''" style="color:red;">{{erreurProjet}}</p>
    </div>
    <br>

    <button type="button" class="btn btn-primary" style="margin-right: 10px;"
      (click)="showDivs(1)">Précédent</button>
    <button type="button" class="btn btn-primary" (click)="setNewProjet()">Suivant</button>
</div>