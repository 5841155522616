

<div id="navbar" class="navbar navbar-blanc">

    <div class="content backround-class">

    </div>

    <div class="container active-container">
        <div style="position: absolute; background-color: #fe5a0e; top: 0px; left:0px; height: 5px; width: 100%;">
        </div>

        <div class="topbar">
            <a title="06 73 51 60 30" href="tel:06 73 51 60 30" class=""><img src="/assets/images/icon1.png">
                <span class="d-none d-md-block">06 73 51 60 30</span></a>
            <a title="CONTACT" href="/contact" class=""><img src="/assets/images/icon2.png">
                <span class="d-none d-md-block">info@aaconcept-renov.com</span></a>
        </div>

        <ul class="navbar-list" style="padding: 0px;">
            
            <li class="item-sous-navbar">
                <img src="./assets/images/Untitled-2.png" class="img-fluid" alt="">
            </li>

            <li class="item-sous-navbar">
                <p style="margin-top:40px;">“” La qualité de notre travail est le produit de notre professionnalisme “”</p>
            </li>

            <li class="item-sous-navbar">
                <p style="margin-top:40px; color: transparent;">“” La qualité de notre travail est le pr</p>
            </li>
            
            <li class="item-sous-navbar">
                <i class="fa fa-moon" style="margin-top:40px; margin-left: 100px;"></i>
            </li>
              
        </ul>
    </div>
    
    <div class="content active-container">
    
        <div class="item-sous-navbar-2 linear-gradient" >
            <ul class="navbar-list2">
               <li><a [routerLink]="['/accueil']">Accueil</a></li>
               <li><a [routerLink]="['/Presentation']">Présentation</a> </li>
               <li><a [routerLink]="['/Realisations']">Réalisations</a> </li>
               <li><a [routerLink]="['/Services']">Services</a> </li>
               <li><a [routerLink]="['/Avantapres']">Avant/Après</a> </li>
               <li><a [routerLink]="['/Images']">Avis clients </a> </li>
               <li><a [routerLink]="['/Contact']">Contact</a> </li>
               <li><a [routerLink]="['/Demandedevis']">Devis</a> </li>
            </ul>
        </div>

    </div>

    <div class="content active-content">

        <ul class="navbar-list" style="margin: 0px; padding: 0px;">
            
            <li class="item-sous-navbar" >
           
                <img src="./assets/images/Untitled-2.png" class="img-fluid" style="height: 80px;" alt="">
           
            </li>
            
            <li class="item-sous-navbar" style="margin-top:50px; margin-bottom: 10px;">
                <i class="fa fa-moon"></i>
                <span class="icon-small-navbar" (click)="displaySousMenu()">
                    <i class="fas fa-bars icon-menu active"></i>
                    <i class="fas fa-times icon-menu desactive"></i>
                </span>
            </li>
              
        </ul>

       
        <ul class="navbar-list-mobile">
            <li><a [routerLink]="['/accueil']">Accueil</a></li>
            <li><a [routerLink]="['/Presentation']">Présentation</a> </li>
            <li><a [routerLink]="['/Realisations']">Réalisations</a> </li>
            <li><a [routerLink]="['/Services']">Services</a> </li>
            <li><a [routerLink]="['/Avantapres']">Avant/Après</a> </li>
            <li><a [routerLink]="['/Images']">Avis clients</a> </li>
            <li><a [routerLink]="['/Contact']">Contact</a> </li>
            <li><a [routerLink]="['/Demandedevis']">Devis</a> </li>
        </ul>

   </div>
</div>
<div style="height: 50px; width: 100%;">

</div>