import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog1-home',
  templateUrl: './blog1-home.component.html',
  styleUrls: ['./blog1-home.component.css']
})
export class Blog1HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
