import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog4-home',
  templateUrl: './blog4-home.component.html',
  styleUrls: ['./blog4-home.component.css']
})
export class Blog4HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
