<div class="w3l-cwp4-sec py-5" style="background-color: #f7f9fa;">
    <div class="container py-md-5 py-3">
      <div class="cwp4-two row align-items-center">
        <div class="cwp4-text col-lg-6">
          <h6 class="sub-titlehny">Industry Corporation!</h6>
          <h3 class="hny-title">Best A Grade Commercial
            &amp; Residential Services</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud.
          </p>

          <ul class="cont-4 mt-lg-5 mt-4">
            <li><span class="fa fa-check-circle-o"></span> Quality Control System, 100% Satisfaction Guarantee</li>
            <li><span class="fa fa-check-circle-o"></span> Highly Professional Staff, Accurate Testing Processes</li>
            <li><span class="fa fa-check-circle-o"></span> Unrivalled workmanship, Professional and Qualified</li>

          </ul>
        </div>
        <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
          <img src="./assets/images/1.jpg" class="img-fluid" alt="">
        </div>
      </div>
    </div>

  </div>
