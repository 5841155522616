
<section class="w3l-stats py-lg-5 py-5" id="partenaire" style="background-color: #f7f9fa;">
    
    <h2 style="text-align: center;"> Nos Partenaires</h2><br>
    <div class="gallery-inner container" style="position: relative; padding:0px 20px;">
        
        <div class="row conteneur-items">
            
            <div class="col-lg-3 col-6 item-partenaire">
                <img src="assets/images/partenaire/275384228_514023746819518_4409511459849493682_n.jpg" alt="product" class="img-fluid">
            </div>
            
            <div class="col-lg-3 col-6 item-partenaire">
                <img src="assets/images/partenaire/275204253_1127816064426817_3535300798542055341_n.jpg" alt="product" class="img-fluid">
            </div>
            
            <div class="col-lg-3 col-6 item-partenaire">
                <img src="assets/images/partenaire/275171354_640373643737979_2825514405456413815_n.jpg" alt="product" class="img-fluid">
            </div>
            
            <div class="col-lg-3 col-6 item-partenaire">
                <img src="assets/images/partenaire/275184270_293148189597602_4892376894882186358_n.jpg" alt="product" class="img-fluid">
            </div>

            <div class="col-lg-3 col-6 item-partenaire">
                <img src="assets/images/partenaire/275333703_626917055068397_127818088492896685_n.jpg" alt="product" class="img-fluid">
            </div>

          
            <div style="position:absolute; width:100%; left:0px; height: 10%; margin:auto; top:0px; bottom:0px; padding: 10px;">
                <button class="w3-button w3-display-left" (click)="plusDivs(-1)">&#10094; </button>
                <button class="w3-button w3-display-right" (click)="plusDivs(+1)" style="float:right;">&#10095;</button>
            </div>
        </div>

       

    </div>

    
  </section>