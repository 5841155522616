<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<div id="pagebody">
   
   <app-navbar></app-navbar>
   
      <router-outlet></router-outlet>
   
   
      <app-footer></app-footer>
      
   
   <button (click)="scrollTop()" type="button" class="btn btn-success btn-scroll-top"> <i class="fas fa-arrow-up"></i> </button>
   <a   href="https://wa.me/+33 06 73 51 60 30?text=I'm%20interested%20in%20your%20car%20for%20sale" class="btn btn-success btn-scroll-top-whats"><img src="/assets/images/whatsapp.jpg" style="height: 40px; width: 40px;"></a>

   
</div>