  <app-sliderpage name="Présentation"></app-sliderpage>

  <section class="w3l-grids-3 py-5" id="about">
    <div>
      <h2 style="color: #293f05; text-align: center;"> Qui somme nous ? </h2>
    </div>
     <div class="container py-md-5 py-3" style="padding-top: 0px !important;">
       <div class="row bottom-ab-grids align-items-center">
 
         <div class="col-lg-6 bottom-ab-left">
           <h6 class="sub-titlehny"> <img src="./assets/images/Untitled-2.png" class="img-fluid" alt=""> </h6>
           <h3 class="hny-title">La qualité de notre travail est le produit de notre professionnalisme  </h3>
           <p class="my-3"> La société A.a.concept renov est fondée en 2015 ,nous mettons à votre disposition une équipe fiable et dévouée.
             Notre entreprise de rénovation vous propose un service hautement professionnel pour 
             la rénovation intérieure et extérieure qui réalise tous travaux  dans les domaines suivants : 
              </p>
         </div>
         <div class="col-lg-6 bottom-ab-right mt-lg-0 mt-3 pl-lg-4">
           <br>
           <br>
           <p> Nettoyage & Hydrofuge de toiture et façade, Sanitaire ,  placo-platre, isolation, Rénovation totale, cloisons sèches, revêtements de sol, carrelage, parquet, peinture, décoration, électricité, ,Crepi 
           </p>
           
           <p class="mt-3">Confier vos travaux de rénovation  à  A.a.concept renov 
            c'est vous assurer un résultat de qualité à des prix attractifs et des délais respectés.
            </p>

            <p>
              La société A.a.concept renov et son équipe, sont à votre service pour vous accompagner dans tous vos projets jusqu’à livraison du chantier.
            </p>

            <p>
              Notre personnel dispose des compétences et de l'expérience pour mener à bien l’ensemble 
              de vos travaux de rénovation

            </p>

         </div>

         <div class="col-lg-6">
          <h2> Nos prestations </h2>
          
          <ul>
             <li class="my-3"> Rénovation Mur : tout type de support (Reconstruction, Jointement, Enduit...). </li>
             <li class="my-3"> Sol (Terrasse, Chemin etc.) : Béton, Pierres, Briques, Carrelages, Pavés. </li>
             <li class="my-3"> Peinture : Selon vos besoins </li>
             <li class="my-3"> Carrelage : Sol avec réagréage si nécessaire et sur support mural. </li>
             <li class="my-3"> Cloisons : Plâtre, Béton cellulaire, Parpaing, Brique... </li>
             <li class="my-3"> Isolation : Selon vos besoins après état des lieux </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <br>
          <ul>
         
              <li class="my-3"> Création ou Rénovation : Cuisine, Salle de bain et WC... </li>
              <li class="my-3"> Tranchée pour fondation, Terrassement </li>
              <li class="my-3"> Rénovation de Mur, Consolidation, Jointement, Enduit... </li>
              <li class="my-3"> Montage de cloisons en Carreaux de Plâtre </li>
              <li class="my-3"> Pose de Plaque de BA13 ou Isolant... </li>
              <li class="my-3"> Déblayage quotidien des gravats pendant les travaux. </li>

          </ul>
         
        </div>
 
       </div>
       <div class="row bottom_grids pt-md-3 text-left">
         <div class="col-lg-4 col-md-6 mt-5">
           <div class="grid-block">
             <a href="#" class="d-block p-lg-4 p-3">
               <span class="fa fa-cubes" aria-hidden="true"></span>
               <h4 class="my-3">Une clientèle diversifiée</h4>
               <p class="">Nous travaillons aussi bien pour les particuliers que pour les professionnels.</p>
             </a>
           </div>
         </div>
         <div class="col-lg-4 col-md-6 mt-5">
           <div class="grid-block">
             <a href="#" class="d-block p-lg-4 p-3">
               <span class="fa fa-building" aria-hidden="true"></span>
               <h4 class="my-3">La qualité de services</h4>
               <p class="">Nous garantissons un résultat de qualité pour votre satisfaction.</p>
             </a>
           </div>
         </div>
         <div class="col-lg-4 col-md-6 mt-5">
           <div class="grid-block">
             <a href="#" class="d-block p-lg-4 p-3">
               <span class="fa fa-podcast" aria-hidden="true"></span>
               <h4 class="my-3">Notre polyvalence</h4>
               <p class="">Nous réalisons tout type de projet : construction et rénovation.</p>
             </a>
           </div>
         </div>
       </div>
     </div>
   </section>
 
