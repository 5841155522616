import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avantapres',
  templateUrl: './avantapres.component.html',
  styleUrls: ['./avantapres.component.css']
})
export class AvantapresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
