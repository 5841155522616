<div class="content">
    <h4> 1- Sélectionnez le type de travaux que vous souhaitez réaliser</h4>

    <br>

    <div style="width: 100%; display: block;">
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-construction-renovation_hover.png">
          <p>Construction Renovation</p>
        </div>
      </div>
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-energies-renouvelables_hover.png">
          <p>Energies Renouvelables</p>
        </div>
      </div>
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-exterieur-jardins_hover.png">
          <p>Exterieur Jardins</p>
        </div>
      </div>
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-interieurs_hover.png">
          <p>Renovation Interieurs</p>
        </div>
      </div>

      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-isolation_hover.png">
          <p>Renovation Isolation</p>
        </div>
      </div>
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-maconnerie_hover.png">
          <p>Renovation Maconnerie</p>
        </div>
      </div>
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-menuiseries_hover.png">
          <p>Renovation Menuiseries</p>
        </div>
      </div>
      <div class="col-sm-3 icon-list">
        <div (click)="setTypeTravaux($event)">
          <img src="./assets/images/icons/picto_univ-plomberie-chauffage_hover.png">
          <p>Plomberie Chauffage</p>
        </div>
      </div>

    </div>

    <br> <br>


  </div>