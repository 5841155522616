<section class="w3l-grids-3 py-5" id="about">
  <div>
    <h2 style="color: white; text-align: center;background: linear-gradient(to right, #8bdf06, rgb(94, 185, 228));"> Avis de clients </h2>
  </div>
  <br>
  <br>
  <br>      
<div class="container py-md-5 py-3" style="padding-top: 0px !important;">
  <div class="row bottom-ab-grids align-items-center">

    <div class="col-lg-6 bottom-ab-left w3l-cwp4-sec py-5">
      <span style="float: left;padding: 15px; margin:auto"> <img style="width: 180px;height: 160px;;" src="./assets/images/client1.jpg" class="img-fluid" alt=""> </span>
      <p  style="z-index:-1;
        letter-spacing: 2px; font-family: Arial, Helvetica, sans-serif,solid,cursive; padding: 30px;">Swann Haddou &#9997; recommande Aaconceptrenovo..  </p>
      <br>
      <br>
      <br>      
      <br>
      <p class="my-3"> Ma rénovation est terminée et je dois dire que je suis très satisfaite du résultat !
        Une entreprise sérieuse et soucieus de bien faire,<a [routerLink]="['/Images']" style="outline: none; text-decoration: none; color:black" >Lire la suite.....</a> 
      </p>
    </div>
    <div class="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
      <img src="./assets/images/avis1.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
</section> 