<app-sliderpage name="Services"></app-sliderpage>




<div class="content">
    <div class="row" style="margin:0px;">
  
    
      <div class="col-sm-3" style="margin-top:90px;">
        <ul>
            <li><a [routerLink]="['/Enduit-decoratif']">Enduit Decoratif</a></li>
            <li><a [routerLink]="['/Nettoyage-toiture']">Nettoyage Toiture</a></li>
            <li><a [routerLink]="['/Renovation-facade']">Renovation Facade</a></li>
            <li><a [routerLink]="['/Renovation-interieur']">Renovation Interieur</a></li>
        </ul> 
     </div>
           
            <div class="col-sm-9">
                <br>
                <h2 style="text-align: center;">Nettoyage Toiture</h2>
             
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                      <img
                      src="assets/images/nettoyage de toiture/242963312_386119546547489_3670760419349480541_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                  
                      
                      <img
                      src="assets/images/nettoyage de toiture/243113873_222495623206371_2321874364698643264_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Wintry Mountain Landscape"
                      />
                      <img
                      src="assets/images/nettoyage de toiture/243209429_673265273656598_3735081411463269413_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Mountains in the Clouds"
                      />
                  
                      <img
                      src="assets/images/nettoyage de toiture/243529151_1077987913015353_5278394926116661356_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />

                      <img
                      src="assets/images/nettoyage de toiture/245048409_576237576954694_2150104398141491063_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />
                      <img
                      src="assets/images/nettoyage de toiture/245051897_1188330798321346_8860901168198335740_n.jpg"
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />
                  
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img
                        src="assets/images/nettoyage de toiture/245054737_268500888502555_3309443269232755976_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                        <img
                        src="assets/images/nettoyage de toiture/245055764_1024556734762800_656663447056091079_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                  
                        <img
                        src="assets/images/nettoyage de toiture/245057579_581810533264877_3996289314027631986_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                        <img
                        src="assets/images/nettoyage de toiture/245073136_930107897713852_4411981918757320031_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                  
                        <img
                        src="assets/images/nettoyage de toiture/245075906_1079154612837072_688406560433268482_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                        <img
                        src="assets/images/nettoyage de toiture/245078783_394357845473840_3781199504103532978_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                  
                      </div>

                  
                      <div class="col-lg-4 mb-4 mb-lg-0">
                        <img
                        src="assets/images/nettoyage de toiture/245094812_562966885030523_1037788564888580515_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                        <img
                        src="assets/images/nettoyage de toiture/245225141_1061364324632117_3612475433739087047_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                  
                        <img
                        src="assets/images/nettoyage de toiture/245413415_617881972717448_6042871495913891_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                        <img
                        src="assets/images/nettoyage de toiture/245532348_1826107537592478_4401664391926898567_n.jpg"
                          class="w-100 shadow-1-strong rounded mb-4"
                          alt="Waves at Sea"
                        />
                  
                      </div>

                  
                     
                    
                  </div>
            </div>
        
    </div>
</div>